.fa {
  background: #29ca8e;
  border-radius: 100%;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
}

.fa-envelope {
  background: #092570;
  border-radius: 100%;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
}

.dropdown-item i {
  width: 30px;
  color: #ffffff;
}

.dropdown-item a:hover {
  color: #000000;
  text-decoration: none; 
}

.dropdown-item a:focus {
  color: #ffffff;
  text-decoration: none; 
}

.dropdown-item a {
  color: #000000;
  text-decoration: none; 
}

.sidebar {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: #fff;
  background: #092d5f;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 70%) !important;
}

.sidebar-fixed .sidebar {
  width: 200px;
}

.app-body .sidebar {
  flex: 0 0 200px !important;
}

.sidebar .sidebar-nav {
  width: 200px !important;
}

.sidebar .nav {
  width: 200px !important;
}

.sidebar .nav-link {
  display: flex;
  align-items: center;
}

.sidebar .nav-link .nav-icon {
  margin-right: 0.7rem;
  font-size: 1rem;
  line-height: 1;
  background: rgba(108, 114, 147, 0.2);
  width: 31px;
  height: 31px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.sidebar .nav .nav-item .nav-link {
  height: 46px;
  border-radius: 0 100px 100px 0;
  cursor: pointer;
  font-size: 16px;
}

.sidebar .nav .nav-item .nav-link.active {
  background: #0f1015;
}

.sidebar .nav .nav-item .nav-link.active:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #0090e7;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
  margin-left: 200px;
} 

.sidebar .nav-dropdown-items .nav-item {
  padding: 0px 0px 0px 15px !important;
}

.sidebar .nav-dropdown-items .nav-item .nav-link {
  height: 44px !important;
  font-size: 14px !important;
}

.sidebar .nav-dropdown-items .nav-link .nav-icon {
  font-size: 0.9rem !important;
  width: 28px !important;
  height: 28px !important;
}

.sidebar .nav-dropdown-toggle::before {
  width: 12px !important;
  height: 12px !important;
}

html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 200px;
}

.img-avatar:active {
  transform: scale(0.6);
}


@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-link {
    padding-left: 15px !important;
  }
  .sidebar-minimized .sidebar .nav-link:hover {
    width: 160px !important;
  }
  .sidebar-minimized .sidebar .nav-link .nav-icon {
    display: flex !important;
    float: none !important;
    width: 31px !important;
    font-size: 18px !important;
    background: none !important;
    margin-right: 1.2rem !important;
  }
}

@media (max-width: 992px) {
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 0px;
  }
}

.app-primary-color{
  background: #092d5f;
  border-bottom: none !important;
}